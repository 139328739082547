<template>
  <div class="p-2 d-flex text-center">
    <div class="create-sub-text"><strong class="creation"> Create a character </strong> to unlock this feature !</div>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="sass">
.create-sub-text
  color: #214163
.creation
  color: #AE38E5
</style>
